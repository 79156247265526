import Layout from "./Components/layout";

function App() {
  return (
    <Layout />

  )
}

export default App;
