// All countries
// length 252
const countries = {
	AF: { name: "Afghanistan", symbol: "؋", currency: "AFN" },
	AX: { name: "Aland Islands", symbol: "€", currency: "EUR" },
	AL: { name: "Albania", symbol: "Lek", currency: "ALL" },
	DZ: { name: "Algeria", symbol: "دج", currency: "DZD" },
	AS: { name: "American Samoa", symbol: "$", currency: "USD" },
	AD: { name: "Andorra", symbol: "€", currency: "EUR" },
	AO: { name: "Angola", symbol: "Kz", currency: "AOA" },
	AI: { name: "Anguilla", symbol: "$", currency: "XCD" },
	AQ: { name: "Antarctica", symbol: "$", currency: "AAD" },
	AG: { name: "Antigua and Barbuda", symbol: "$", currency: "XCD" },
	AR: { name: "Argentina", symbol: "$", currency: "ARS" },
	AM: { name: "Armenia", symbol: "֏", currency: "AMD" },
	AW: { name: "Aruba", symbol: "ƒ", currency: "AWG" },
	AU: { name: "Australia", symbol: "$", currency: "AUD" },
	AT: { name: "Austria", symbol: "€", currency: "EUR" },
	AZ: { name: "Azerbaijan", symbol: "m", currency: "AZN" },
	BS: { name: "Bahamas", symbol: "B$", currency: "BSD" },
	BH: { name: "Bahrain", symbol: ".د.ب", currency: "BHD" },
	BD: { name: "Bangladesh", symbol: "৳", currency: "BDT" },
	BB: { name: "Barbados", symbol: "Bds$", currency: "BBD" },
	BY: { name: "Belarus", symbol: "Br", currency: "BYN" },
	BE: { name: "Belgium", symbol: "€", currency: "EUR" },
	BZ: { name: "Belize", symbol: "$", currency: "BZD" },
	BJ: { name: "Benin", symbol: "CFA", currency: "XOF" },
	BM: { name: "Bermuda", symbol: "$", currency: "BMD" },
	BT: { name: "Bhutan", symbol: "Nu.", currency: "BTN" },
	BO: { name: "Bolivia", symbol: "Bs.", currency: "BOB" },
	BQ: { name: "Bonaire, Sint Eustatius and Saba", symbol: "$", currency: "USD" },
	BA: { name: "Bosnia and Herzegovina", symbol: "KM", currency: "BAM" },
	BW: { name: "Botswana", symbol: "P", currency: "BWP" },
	BV: { name: "Bouvet Island", symbol: "kr", currency: "NOK" },
	BR: { name: "Brazil", symbol: "R$", currency: "BRL" },
	IO: { name: "British Indian Ocean Territory", symbol: "$", currency: "USD" },
	BN: { name: "Brunei Darussalam", symbol: "B$", currency: "BND" },
	BG: { name: "Bulgaria", symbol: "Лв.", currency: "BGN" },
	BF: { name: "Burkina Faso", symbol: "CFA", currency: "XOF" },
	BI: { name: "Burundi", symbol: "FBu", currency: "BIF" },
	KH: { name: "Cambodia", symbol: "KHR", currency: "KHR" },
	CM: { name: "Cameroon", symbol: "FCFA", currency: "XAF" },
	CA: { name: "Canada", symbol: "$", currency: "CAD" },
	CV: { name: "Cape Verde", symbol: "$", currency: "CVE" },
	KY: { name: "Cayman Islands", symbol: "$", currency: "KYD" },
	CF: { name: "Central African Republic", symbol: "FCFA", currency: "XAF" },
	TD: { name: "Chad", symbol: "FCFA", currency: "XAF" },
	CL: { name: "Chile", symbol: "$", currency: "CLP" },
	CN: { name: "China", symbol: "¥", currency: "CNY" },
	CX: { name: "Christmas Island", symbol: "$", currency: "AUD" },
	CC: { name: "Cocos (Keeling) Islands", symbol: "$", currency: "AUD" },
	CO: { name: "Colombia", symbol: "$", currency: "COP" },
	KM: { name: "Comoros", symbol: "CF", currency: "KMF" },
	CG: { name: "Congo", symbol: "FC", currency: "XAF" },
	CD: { name: "Congo, Democratic Republic of the Congo", symbol: "FC", currency: "CDF" },
	CK: { name: "Cook Islands", symbol: "$", currency: "NZD" },
	CR: { name: "Costa Rica", symbol: "₡", currency: "CRC" },
	CI: { name: "Cote D'Ivoire", symbol: "CFA", currency: "XOF" },
	HR: { name: "Croatia", symbol: "kn", currency: "HRK" },
	CU: { name: "Cuba", symbol: "$", currency: "CUP" },
	CW: { name: "Curacao", symbol: "ƒ", currency: "ANG" },
	CY: { name: "Cyprus", symbol: "€", currency: "EUR" },
	CZ: { name: "Czech Republic", symbol: "Kč", currency: "CZK" },
	DK: { name: "Denmark", symbol: "Kr.", currency: "DKK" },
	DJ: { name: "Djibouti", symbol: "Fdj", currency: "DJF" },
	DM: { name: "Dominica", symbol: "$", currency: "XCD" },
	DO: { name: "Dominican Republic", symbol: "$", currency: "DOP" },
	EC: { name: "Ecuador", symbol: "$", currency: "USD" },
	EG: { name: "Egypt", symbol: "ج.م", currency: "EGP" },
	SV: { name: "El Salvador", symbol: "$", currency: "USD" },
	GQ: { name: "Equatorial Guinea", symbol: "FCFA", currency: "XAF" },
	ER: { name: "Eritrea", symbol: "Nfk", currency: "ERN" },
	EE: { name: "Estonia", symbol: "€", currency: "EUR" },
	ET: { name: "Ethiopia", symbol: "Nkf", currency: "ETB" },
	FK: { name: "Falkland Islands (Malvinas)", symbol: "£", currency: "FKP" },
	FO: { name: "Faroe Islands", symbol: "Kr.", currency: "DKK" },
	FJ: { name: "Fiji", symbol: "FJ$", currency: "FJD" },
	FI: { name: "Finland", symbol: "€", currency: "EUR" },
	FR: { name: "France", symbol: "€", currency: "EUR" },
	GF: { name: "French Guiana", symbol: "€", currency: "EUR" },
	PF: { name: "French Polynesia", symbol: "₣", currency: "XPF" },
	TF: { name: "French Southern Territories", symbol: "€", currency: "EUR" },
	GA: { name: "Gabon", symbol: "FCFA", currency: "XAF" },
	GM: { name: "Gambia", symbol: "D", currency: "GMD" },
	GE: { name: "Georgia", symbol: "ლ", currency: "GEL" },
	DE: { name: "Germany", symbol: "€", currency: "EUR" },
	GH: { name: "Ghana", symbol: "GH₵", currency: "GHS" },
	GI: { name: "Gibraltar", symbol: "£", currency: "GIP" },
	GR: { name: "Greece", symbol: "€", currency: "EUR" },
	GL: { name: "Greenland", symbol: "Kr.", currency: "DKK" },
	GD: { name: "Grenada", symbol: "$", currency: "XCD" },
	GP: { name: "Guadeloupe", symbol: "€", currency: "EUR" },
	GU: { name: "Guam", symbol: "$", currency: "USD" },
	GT: { name: "Guatemala", symbol: "Q", currency: "GTQ" },
	GG: { name: "Guernsey", symbol: "£", currency: "GBP" },
	GN: { name: "Guinea", symbol: "FG", currency: "GNF" },
	GW: { name: "Guinea-Bissau", symbol: "CFA", currency: "XOF" },
	GY: { name: "Guyana", symbol: "$", currency: "GYD" },
	HT: { name: "Haiti", symbol: "G", currency: "HTG" },
	HM: { name: "Heard Island and McDonald Islands", symbol: "$", currency: "AUD" },
	VA: { name: "Holy See (Vatican City State)", symbol: "€", currency: "EUR" },
	HN: { name: "Honduras", symbol: "L", currency: "HNL" },
	HK: { name: "Hong Kong", symbol: "$", currency: "HKD" },
	HU: { name: "Hungary", symbol: "Ft", currency: "HUF" },
	IS: { name: "Iceland", symbol: "kr", currency: "ISK" },
	IN: { name: "India", symbol: "₹", currency: "INR" },
	ID: { name: "Indonesia", symbol: "Rp", currency: "IDR" },
	IR: { name: "Iran, Islamic Republic of", symbol: "﷼", currency: "IRR" },
	IQ: { name: "Iraq", symbol: "د.ع", currency: "IQD" },
	IE: { name: "Ireland", symbol: "€", currency: "EUR" },
	IM: { name: "Isle of Man", symbol: "£", currency: "GBP" },
	IL: { name: "Israel", symbol: "₪", currency: "ILS" },
	IT: { name: "Italy", symbol: "€", currency: "EUR" },
	JM: { name: "Jamaica", symbol: "J$", currency: "JMD" },
	JP: { name: "Japan", symbol: "¥", currency: "JPY" },
	JE: { name: "Jersey", symbol: "£", currency: "GBP" },
	JO: { name: "Jordan", symbol: "ا.د", currency: "JOD" },
	KZ: { name: "Kazakhstan", symbol: "лв", currency: "KZT" },
	KE: { name: "Kenya", symbol: "KSh", currency: "KES" },
	KI: { name: "Kiribati", symbol: "$", currency: "AUD" },
	KP: { name: "Korea, Democratic People's Republic of", symbol: "₩", currency: "KPW" },
	KR: { name: "Korea, Republic of", symbol: "₩", currency: "KRW" },
	XK: { name: "Kosovo", symbol: "€", currency: "EUR" },
	KW: { name: "Kuwait", symbol: "ك.د", currency: "KWD" },
	KG: { name: "Kyrgyzstan", symbol: "лв", currency: "KGS" },
	LA: { name: "Lao People's Democratic Republic", symbol: "₭", currency: "LAK" },
	LV: { name: "Latvia", symbol: "€", currency: "EUR" },
	LB: { name: "Lebanon", symbol: "£", currency: "LBP" },
	LS: { name: "Lesotho", symbol: "L", currency: "LSL" },
	LR: { name: "Liberia", symbol: "$", currency: "LRD" },
	LY: { name: "Libyan Arab Jamahiriya", symbol: "د.ل", currency: "LYD" },
	LI: { name: "Liechtenstein", symbol: "CHf", currency: "CHF" },
	LT: { name: "Lithuania", symbol: "€", currency: "EUR" },
	LU: { name: "Luxembourg", symbol: "€", currency: "EUR" },
	MO: { name: "Macao", symbol: "$", currency: "MOP" },
	MK: { name: "Macedonia, the Former Yugoslav Republic of", symbol: "ден", currency: "MKD" },
	MG: { name: "Madagascar", symbol: "Ar", currency: "MGA" },
	MW: { name: "Malawi", symbol: "MK", currency: "MWK" },
	MY: { name: "Malaysia", symbol: "RM", currency: "MYR" },
	MV: { name: "Maldives", symbol: "Rf", currency: "MVR" },
	ML: { name: "Mali", symbol: "CFA", currency: "XOF" },
	MT: { name: "Malta", symbol: "€", currency: "EUR" },
	MH: { name: "Marshall Islands", symbol: "$", currency: "USD" },
	MQ: { name: "Martinique", symbol: "€", currency: "EUR" },
	MR: { name: "Mauritania", symbol: "MRU", currency: "MRO" },
	MU: { name: "Mauritius", symbol: "₨", currency: "MUR" },
	YT: { name: "Mayotte", symbol: "€", currency: "EUR" },
	MX: { name: "Mexico", symbol: "$", currency: "MXN" },
	FM: { name: "Micronesia, Federated States of", symbol: "$", currency: "USD" },
	MD: { name: "Moldova, Republic of", symbol: "L", currency: "MDL" },
	MC: { name: "Monaco", symbol: "€", currency: "EUR" },
	MN: { name: "Mongolia", symbol: "₮", currency: "MNT" },
	ME: { name: "Montenegro", symbol: "€", currency: "EUR" },
	MS: { name: "Montserrat", symbol: "$", currency: "XCD" },
	MA: { name: "Morocco", symbol: "DH", currency: "MAD" },
	MZ: { name: "Mozambique", symbol: "MT", currency: "MZN" },
	MM: { name: "Myanmar", symbol: "K", currency: "MMK" },
	NA: { name: "Namibia", symbol: "$", currency: "NAD" },
	NR: { name: "Nauru", symbol: "$", currency: "AUD" },
	NP: { name: "Nepal", symbol: "₨", currency: "NPR" },
	NL: { name: "Netherlands", symbol: "€", currency: "EUR" },
	AN: { name: "Netherlands Antilles", symbol: "NAf", currency: "ANG" },
	NC: { name: "New Caledonia", symbol: "₣", currency: "XPF" },
	NZ: { name: "New Zealand", symbol: "$", currency: "NZD" },
	NI: { name: "Nicaragua", symbol: "C$", currency: "NIO" },
	NE: { name: "Niger", symbol: "CFA", currency: "XOF" },
	NG: { name: "Nigeria", symbol: "₦", currency: "NGN" },
	NU: { name: "Niue", symbol: "$", currency: "NZD" },
	NF: { name: "Norfolk Island", symbol: "$", currency: "AUD" },
	MP: { name: "Northern Mariana Islands", symbol: "$", currency: "USD" },
	NO: { name: "Norway", symbol: "kr", currency: "NOK" },
	OM: { name: "Oman", symbol: ".ع.ر", currency: "OMR" },
	PK: { name: "Pakistan", symbol: "₨", currency: "PKR" },
	PW: { name: "Palau", symbol: "$", currency: "USD" },
	PS: { name: "Palestinian Territory, Occupied", symbol: "₪", currency: "ILS" },
	PA: { name: "Panama", symbol: "B/.", currency: "PAB" },
	PG: { name: "Papua New Guinea", symbol: "K", currency: "PGK" },
	PY: { name: "Paraguay", symbol: "₲", currency: "PYG" },
	PE: { name: "Peru", symbol: "S/.", currency: "PEN" },
	PH: { name: "Philippines", symbol: "₱", currency: "PHP" },
	PN: { name: "Pitcairn", symbol: "$", currency: "NZD" },
	PL: { name: "Poland", symbol: "zł", currency: "PLN" },
	PT: { name: "Portugal", symbol: "€", currency: "EUR" },
	PR: { name: "Puerto Rico", symbol: "$", currency: "USD" },
	QA: { name: "Qatar", symbol: "ق.ر", currency: "QAR" },
	RE: { name: "Reunion", symbol: "€", currency: "EUR" },
	RO: { name: "Romania", symbol: "lei", currency: "RON" },
	RU: { name: "Russian Federation", symbol: "₽", currency: "RUB" },
	RW: { name: "Rwanda", symbol: "FRw", currency: "RWF" },
	BL: { name: "Saint Barthelemy", symbol: "€", currency: "EUR" },
	SH: { name: "Saint Helena", symbol: "£", currency: "SHP" },
	KN: { name: "Saint Kitts and Nevis", symbol: "$", currency: "XCD" },
	LC: { name: "Saint Lucia", symbol: "$", currency: "XCD" },
	MF: { name: "Saint Martin", symbol: "€", currency: "EUR" },
	PM: { name: "Saint Pierre and Miquelon", symbol: "€", currency: "EUR" },
	VC: { name: "Saint Vincent and the Grenadines", symbol: "$", currency: "XCD" },
	WS: { name: "Samoa", symbol: "SAT", currency: "WST" },
	SM: { name: "San Marino", symbol: "€", currency: "EUR" },
	ST: { name: "Sao Tome and Principe", symbol: "Db", currency: "STD" },
	SA: { name: "Saudi Arabia", symbol: "﷼", currency: "SAR" },
	SN: { name: "Senegal", symbol: "CFA", currency: "XOF" },
	RS: { name: "Serbia", symbol: "din", currency: "RSD" },
	CS: { name: "Serbia and Montenegro", symbol: "din", currency: "RSD" },
	SC: { name: "Seychelles", symbol: "SRe", currency: "SCR" },
	SL: { name: "Sierra Leone", symbol: "Le", currency: "SLL" },
	SG: { name: "Singapore", symbol: "$", currency: "SGD" },
	SX: { name: "St Martin", symbol: "ƒ", currency: "ANG" },
	SK: { name: "Slovakia", symbol: "€", currency: "EUR" },
	SI: { name: "Slovenia", symbol: "€", currency: "EUR" },
	SB: { name: "Solomon Islands", symbol: "Si$", currency: "SBD" },
	SO: { name: "Somalia", symbol: "Sh.so.", currency: "SOS" },
	ZA: { name: "South Africa", symbol: "R", currency: "ZAR" },
	GS: { name: "South Georgia and the South Sandwich Islands", symbol: "£", currency: "GBP" },
	SS: { name: "South Sudan", symbol: "£", currency: "SSP" },
	ES: { name: "Spain", symbol: "€", currency: "EUR" },
	LK: { name: "Sri Lanka", symbol: "Rs", currency: "LKR" },
	SD: { name: "Sudan", symbol: ".س.ج", currency: "SDG" },
	SR: { name: "Suriname", symbol: "$", currency: "SRD" },
	SJ: { name: "Svalbard and Jan Mayen", symbol: "kr", currency: "NOK" },
	SZ: { name: "Swaziland", symbol: "E", currency: "SZL" },
	SE: { name: "Sweden", symbol: "kr", currency: "SEK" },
	CH: { name: "Switzerland", symbol: "CHf", currency: "CHF" },
	SY: { name: "Syrian Arab Republic", symbol: "LS", currency: "SYP" },
	TW: { name: "Taiwan, Province of China", symbol: "$", currency: "TWD" },
	TJ: { name: "Tajikistan", symbol: "SM", currency: "TJS" },
	TZ: { name: "Tanzania, United Republic of", symbol: "TSh", currency: "TZS" },
	TH: { name: "Thailand", symbol: "฿", currency: "THB" },
	TL: { name: "Timor-Leste", symbol: "$", currency: "USD" },
	TG: { name: "Togo", symbol: "CFA", currency: "XOF" },
	TK: { name: "Tokelau", symbol: "$", currency: "NZD" },
	TO: { name: "Tonga", symbol: "$", currency: "TOP" },
	TT: { name: "Trinidad and Tobago", symbol: "$", currency: "TTD" },
	TN: { name: "Tunisia", symbol: "ت.د", currency: "TND" },
	TR: { name: "Turkey", symbol: "₺", currency: "TRY" },
	TM: { name: "Turkmenistan", symbol: "T", currency: "TMT" },
	TC: { name: "Turks and Caicos Islands", symbol: "$", currency: "USD" },
	TV: { name: "Tuvalu", symbol: "$", currency: "AUD" },
	UG: { name: "Uganda", symbol: "USh", currency: "UGX" },
	UA: { name: "Ukraine", symbol: "₴", currency: "UAH" },
	AE: { name: "United Arab Emirates", symbol: "إ.د", currency: "AED" },
	GB: { name: "United Kingdom", symbol: "£", currency: "GBP" },
	US: { name: "United States", symbol: "$", currency: "USD" },
	UM: { name: "United States Minor Outlying Islands", symbol: "$", currency: "USD" },
	UY: { name: "Uruguay", symbol: "$", currency: "UYU" },
	UZ: { name: "Uzbekistan", symbol: "лв", currency: "UZS" },
	VU: { name: "Vanuatu", symbol: "VT", currency: "VUV" },
	VE: { name: "Venezuela", symbol: "Bs", currency: "VEF" },
	VN: { name: "Viet Nam", symbol: "₫", currency: "VND" },
	VG: { name: "Virgin Islands, British", symbol: "$", currency: "USD" },
	VI: { name: "Virgin Islands, U.s.", symbol: "$", currency: "USD" },
	WF: { name: "Wallis and Futuna", symbol: "₣", currency: "XPF" },
	EH: { name: "Western Sahara", symbol: "MAD", currency: "MAD" },
	YE: { name: "Yemen", symbol: "﷼", currency: "YER" },
	ZM: { name: "Zambia", symbol: "ZK", currency: "ZMW" },
	ZW: { name: "Zimbabwe", symbol: "$", currency: "ZWL" }
};

export default countries;