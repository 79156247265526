const { Country } = require("../Modal/countryModal");

const countryList = [
  new Country("AED", "AE"),
  new Country("AFN", "AF"),
  new Country("XCD", "AG"),
  new Country("ALL", "AL"),
  new Country("AMD", "AM"),
  new Country("ANG", "AN"),
  new Country("AOA", "AO"),
  new Country("AQD", "AQ"),
  new Country("ARS", "AR"),
  new Country("AUD", "AU"),
  new Country("AZN", "AZ"),
  new Country("BAM", "BA"),
  new Country("BBD", "BB"),
  new Country("BDT", "BD"),
  new Country("XOF", "BE"),
  new Country("BGN", "BG"),
  new Country("BHD", "BH"),
  new Country("BIF", "BI"),
  new Country("BMD", "BM"),
  new Country("BND", "BN"),
  new Country("BOB", "BO"),
  new Country("BRL", "BR"),
  new Country("BSD", "BS"),
  new Country("NOK", "BV"),
  new Country("BWP", "BW"),
  new Country("BYR", "BY"),
  new Country("BZD", "BZ"),
  new Country("CAD", "CA"),
  new Country("CDF", "CD"),
  new Country("XAF", "CF"),
  new Country("CHF", "CH"),
  new Country("CLP", "CL"),
  new Country("CNY", "CN"),
  new Country("COP", "CO"),
  new Country("CRC", "CR"),
  new Country("CUP", "CU"),
  new Country("CVE", "CV"),
  new Country("CYP", "CY"),
  new Country("CZK", "CZ"),
  new Country("DJF", "DJ"),
  new Country("DKK", "DK"),
  new Country("DOP", "DO"),
  new Country("DZD", "DZ"),
  new Country("ECS", "EC"),
  new Country("EEK", "EE"),
  new Country("EGP", "EG"),
  new Country("ETB", "ET"),
  new Country("EUR", "FR"),
  new Country("FJD", "FJ"),
  new Country("FKP", "FK"),
  new Country("GBP", "GB"),
  new Country("GEL", "GE"),
  new Country("GGP", "GG"),
  new Country("GHS", "GH"),
  new Country("GIP", "GI"),
  new Country("GMD", "GM"),
  new Country("GNF", "GN"),
  new Country("GTQ", "GT"),
  new Country("GYD", "GY"),
  new Country("HKD", "HK"),
  new Country("HNL", "HN"),
  new Country("HRK", "HR"),
  new Country("HTG", "HT"),
  new Country("HUF", "HU"),
  new Country("IDR", "ID"),
  new Country("ILS", "IL"),
  new Country("INR", "IN"),
  new Country("IQD", "IQ"),
  new Country("IRR", "IR"),
  new Country("ISK", "IS"),
  new Country("JMD", "JM"),
  new Country("JOD", "JO"),
  new Country("JPY", "JP"),
  new Country("KES", "KE"),
  new Country("KGS", "KG"),
  new Country("KHR", "KH"),
  new Country("KMF", "KM"),
  new Country("KPW", "KP"),
  new Country("KRW", "KR"),
  new Country("KWD", "KW"),
  new Country("KYD", "KY"),
  new Country("KZT", "KZ"),
  new Country("LAK", "LA"),
  new Country("LBP", "LB"),
  new Country("LKR", "LK"),
  new Country("LRD", "LR"),
  new Country("LSL", "LS"),
  new Country("LTL", "LT"),
  new Country("LVL", "LV"),
  new Country("LYD", "LY"),
  new Country("MAD", "MA"),
  new Country("MDL", "MD"),
  new Country("MGA", "MG"),
  new Country("MKD", "MK"),
  new Country("MMK", "MM"),
  new Country("MNT", "MN"),
  new Country("MOP", "MO"),
  new Country("MRO", "MR"),
  new Country("MTL", "MT"),
  new Country("MUR", "MU"),
  new Country("MVR", "MV"),
  new Country("MWK", "MW"),
  new Country("MXN", "MX"),
  new Country("MYR", "MY"),
  new Country("MZN", "MZ"),
  new Country("NAD", "NA"),
  new Country("XPF", "NC"),
  new Country("NGN", "NG"),
  new Country("NIO", "NI"),
  new Country("NPR", "NP"),
  new Country("NZD", "NZ"),
  new Country("OMR", "OM"),
  new Country("PAB", "PA"),
  new Country("PEN", "PE"),
  new Country("PGK", "PG"),
  new Country("PHP", "PH"),
  new Country("PKR", "PK"),
  new Country("PLN", "PL"),
  new Country("PYG", "PY"),
  new Country("QAR", "QA"),
  new Country("RON", "RO"),
  new Country("RSD", "RS"),
  new Country("RUB", "RU"),
  new Country("RWF", "RW"),
  new Country("SAR", "SA"),
  new Country("SBD", "SB"),
  new Country("SCR", "SC"),
  new Country("SDG", "SD"),
  new Country("SEK", "SE"),
  new Country("SGD", "SG"),
  new Country("SKK", "SK"),
  new Country("SLL", "SL"),
  new Country("SOS", "SO"),
  new Country("SRD", "SR"),
  new Country("STD", "ST"),
  new Country("SVC", "SV"),
  new Country("SYP", "SY"),
  new Country("SZL", "SZ"),
  new Country("THB", "TH"),
  new Country("TJS", "TJ"),
  new Country("TMT", "TM"),
  new Country("TND", "TN"),
  new Country("TOP", "TO"),
  new Country("TRY", "TR"),
  new Country("TTD", "TT"),
  new Country("TWD", "TW"),
  new Country("TZS", "TZ"),
  new Country("UAH", "UA"),
  new Country("UGX", "UG"),
  new Country("USD", "US"),
  new Country("UYU", "UY"),
  new Country("UZS", "UZ"),
  new Country("VEF", "VE"),
  new Country("VND", "VN"),
  new Country("VUV", "VU"),
  new Country("YER", "YE"),
  new Country("ZAR", "ZA"),
  new Country("ZMK", "ZM"),
  new Country("ZWD", "ZW"),
];



export default countryList;
